import { useEffect, useRef, useState } from 'react';
import './App.css';
// import AzureDictaphone, { state } from './components/Dictaphone';
import loadingGif from './assets/loading.gif'
import staticGif from './assets/static.gif'
import activeGif from './assets/active.gif'

// import { useSpeechRecognition } from 'react-speech-recognition';
import { Bot, MessageSquare, Mic, SendHorizontal, StopCircle, User } from 'lucide-react';
import { socket } from './utils/socket';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { ConversationMessage } from './components/Message';
import useUserUUID from './utils/hooks';
import Sidebar from './components/Sidebar';
import { fetchMessagesForSession, saveCurrentSession, sendCallIdToServer } from './utils/api';
import Vapi from "@vapi-ai/web";
import { useStore } from './store/zustand';
import { MessageRoleEnum, MessageTypeEnum } from './types/conversations.type';
import { Message, TranscriptMessageTypeEnum } from './types/conversations.type';
import { BrowserRouter, Routes, Route, useParams, useNavigate } from 'react-router-dom';


export const vapi = new Vapi(import.meta.env.VITE_PUBLIC_VAPI_KEY);

export interface ResponseMessage {
  role: 'assistant' | 'user';
  content: string;
}


export type state = 'audio_playing' | 'mic_active' | 'default' | 'loading' | 'error';


const LoadingMessage = () => (
  <div className="flex mb-6 justify-start">
    <div className="flex items-start space-x-2 max-w-[80%]">
      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#0096D6] text-white">
        <Bot className="w-5 h-5" />
      </div>
      <div className="bg-white border border-gray-200 rounded-2xl p-4 shadow-sm">
        <div className="mt-2 flex space-x-1">
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
          <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
        </div>
      </div>
    </div>
  </div>
);


function WelcomePage({ action }: { action: (mode: 'text' | 'voice') => void }) {
  return (
    <div className="min-h-screen bg-[#f2ebdc] flex flex-col">
      <main className="flex-grow container mx-auto px-4 py-8 sm:py-12 flex flex-col items-center justify-center">
      <h1 className="font-['Inter'] text-3xl sm:text-4xl font-bold text-center mb-4 sm:mb-8 text-gray-800">
          Welcome to HP Engager
        </h1>
        <p className="font-['Inter'] text-lg sm:text-xl font-light text-center mb-8 sm:mb-12 text-gray-600">
          Your AI assistant for information on HP Engage G2 Pro
        </p>

        <div className="mb-8 sm:mb-12">
          <img
            src={staticGif}
            alt="AI Intelligence Visualization"
            width={300}
            height={300}
            className="object-cover rounded-full w-48 h-48 sm:w-64 sm:h-64 md:w-80 md:h-80"
          />
        </div>

        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8 w-full sm:w-auto">
          <Button
            className="bg-[#ff6d00] hover:bg-[#ff6d00]/80  text-white px-6 py-3 rounded-full text-lg w-full sm:w-auto"
            onClick={() => action('text')}
          >
            <MessageSquare className="mr-2 h-5 w-5" /> Interact via Text
          </Button>

          <Button
            className="bg-[#ff6d00] hover:bg-[#ff6d00]/80  text-white px-6 py-3 rounded-full text-lg w-full sm:w-auto"
            onClick={() => action('voice')}
          >
            <Mic className="mr-2 h-5 w-5" /> Interact via Voice
          </Button>
        </div>
      </main>
    </div>
  )
}


export enum CALL_STATUS {
  INACTIVE = "inactive",
  ACTIVE = "active",
  LOADING = "loading",
}

// Wrap the main App component with router logic
function AppWrapper() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/chat/:sessionId" element={<App />} />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const navigate = useNavigate();
  const { sessionId: urlSessionId } = useParams();

  const [showChat, setShowChat] = useState(false)
  const [callStatus, setCallStatus] = useState<CALL_STATUS>(
    CALL_STATUS.INACTIVE
  );

  const setSocketId = useStore((state) => state.setSocketId)
  const socketId = useStore((state) => state.socketId)
  const sessionId = useStore((state) => state.sessionId)
  const generateNewSessionId = useStore((state) => state.generateNewSessionId)
  const setSessionId = useStore((state) => state.setSessionId)

  const setCallId = useStore((state) => state.setCallId)
  const callId = useStore((state) => state.callId)

  const mode = useStore((state) => state.mode)
  const setMode = useStore((state) => state.setMode)


  const start = async () => {

    setCallStatus(CALL_STATUS.LOADING);


    const response = vapi.start(import.meta.env.VITE_VAPI_ASSISTANT_ID, { firstMessageMode: 'assistant-speaks-first' });

    response.then((res: any) => {
      console.log("call", res);
      setCallId(res.id)
      sendCallIdToServer(userUUID, res.id, sessionId)
      setMode('voice')
      setCallStatus(CALL_STATUS.ACTIVE)
      console.log(res.id, 'call id from chat');

      socket.emit('start-call', res.id)
    });
  };
  const stop = () => {
    setMode('chat')
    setCallStatus(CALL_STATUS.LOADING);
    vapi.stop();
    setCallStatus(CALL_STATUS.INACTIVE);

  };
  const onCallEnd = () => {
    console.log("Call has stopped");
    setCallStatus(CALL_STATUS.INACTIVE);
    socket.emit('end-call', callId)
    setCallId(null)
    setMode('chat')
  };

  const onError = (e: any) => {
    setCallStatus(CALL_STATUS.INACTIVE);
    setMode('chat')
    console.error(e);
  };

  useEffect(() => {
    const onMessageUpdate = (message: Message) => {
      if (message.type === MessageTypeEnum.TRANSCRIPT && message.transcriptType === TranscriptMessageTypeEnum.FINAL && message.role === MessageRoleEnum.USER) {
        message.timestamp = new Date();
        setMessages(prevMessages => [...prevMessages, { role: 'user', content: message.transcript }]);
        setActiveTranscript(null)
        setIsLoading(true)

        // if (message.role === MessageRoleEnum.USER) {
        //    socket.emit('chat-request', message.transcript)
        // }
      } else if ((message.type === MessageTypeEnum.TRANSCRIPT && message.transcriptType === TranscriptMessageTypeEnum.PARTIAL && message.role === MessageRoleEnum.USER)) {
        setActiveTranscript({ role: 'user', content: message.transcript })
      }
    }

    vapi.on("message", onMessageUpdate);
    vapi.on("error", onError);
    vapi.on("call-end", onCallEnd);




    return () => {
      vapi.off("message", onMessageUpdate);
      vapi.off("error", onError);
      vapi.off("call-end", onCallEnd);
    }
  }, [])


  useEffect(() => {
    if (urlSessionId) {
      // Load session from URL if present
      setShowChat(true);
      fetchMessagesForSession(urlSessionId).then(messages => {
        setMessages(messages);
        sessionToDeleteRef.current = urlSessionId;
      });
    }
  }, [urlSessionId]);

  const handleStart = (mode: 'text' | 'voice') => {
    if (mode === 'text') {
      setShowChat(true);
      // Navigate to a new session URL
      navigate(`/chat/${sessionId}`);
    } else {
      start();
      setShowChat(true);
      navigate(`/chat/${sessionId}`);
    }
  };

  const [audioSrc, setAudioSrc] = useState<string>('');
  // const [sessionToDelete, setSessionToDelete] = useState<string | null>(null)
  const sessionToDeleteRef = useRef<string | null>(null);

  const [activeTranscript, setActiveTranscript] = useState<ResponseMessage | null>(null);
  const [activity, setActivity] = useState<'idle' | 'user_speaking' | 'loading' | 'responding'>('idle');
  const messagesEndRef = useRef<HTMLDivElement>(null);


  const userUUID = useUserUUID()


  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const audioRef = useRef<HTMLAudioElement>(null);
  const inputRef = useRef<HTMLInputElement>(null)

  const getGifSrc = () => {
    switch (activity) {
      case 'idle':
        return staticGif;
      case 'user_speaking':
      case 'loading':
        return loadingGif;
      case 'responding':
        return activeGif;
      default:
        return staticGif;
    }
  };


  const [userInput, setUserInput] = useState<string>('')

  const [isLoading, setIsLoading] = useState(false);

  const [messages, setMessages] = useState<ResponseMessage[]>([])


  useEffect(() => {
    inputRef.current?.focus()
    if (userUUID) {
      console.log(userUUID);
      socket.connect()
    }
    function onConnect() {
      socket.emit('setup', { userId: userUUID, sessionId });
    }
    const onDisconnect = () => {
      console.log("disconnected");

    }
    socket.on('connect', onConnect);
    socket.on('reconnect', onConnect)
    socket.on('disconnect', onDisconnect);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('reconnect', onConnect)

    }
  }, [userUUID, sessionId])


  useEffect(() => {

    socket.on('chat-ready', () => {
      setIsLoading(false);
      setActivity('idle');

    });

    socket.on('chat-response', (data: any) => {
      if (data.isFinished) {
        setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: data.message }]);
        setActiveTranscript(null);
        setIsLoading(false);
        setActivity('idle');

      } else {
        setIsLoading(false);
        setActiveTranscript({ role: 'assistant', content: data.message });
        setActivity('responding');
      }
    });

    return () => {
      socket.off('chat-ready')
      socket.off('chat-response')
    };
  }, [setMessages]);


  const sendMessage = () => {
    // if (sessionToDeleteRef.current) {
    //   socket.emit('delete-session', sessionToDeleteRef.current)
    // }
    if (!userInput.trim()) return;
    const newMessage: ResponseMessage = { role: 'user', content: userInput };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    socket.emit('chat-request', { messages: [...messages, newMessage] });
    setUserInput('')
    setIsLoading(true)
    setActivity('loading');

  }

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }



  useEffect(() => {
    scrollToBottom();
  }, [messages, activeTranscript]);



  if (showChat) {
    return (
      <div className="flex flex-col h-dvh bg-[#f2ebdc] md:flex-row">
        <Sidebar
          userUUID={userUUID}
          onNewChat={() => {
            socket.disconnect()
            setMessages([]);
            const newSessionId = generateNewSessionId()
            socket.emit('change-session', newSessionId)
            setSessionId(newSessionId)
            sessionToDeleteRef.current = null
            navigate(`/chat/${newSessionId}`)
            socket.connect()

          }}
          onSelectSession={async (newSessionId) => {
            saveCurrentSession(sessionId, messages)
            const newMessages = await fetchMessagesForSession(newSessionId)
            sessionToDeleteRef.current = newSessionId
            socket.emit('change-session', newSessionId)
            setSessionId(newSessionId)
            setMessages(newMessages)

            // Logic to load the selected session
            // This might involve fetching messages for the session and updating the state
          }}
        />        {/* Main Chat Area */}
        <div className="flex-1 flex flex-col h-screen md:h-screen bg-[#f2ebdc]">
          <main className="flex-grow overflow-hidden flex flex-col px-4 md:px-8 lg:px-16">
            <div className="flex-shrink-0 flex flex-col items-center mb-2 py-2">
              <div className="w-32 h-32 md:w-48 md:h-48 relative flex items-center justify-center">
                <img
                  src={getGifSrc()}
                  alt="AI Intelligence Visualization"
                  className="absolute w-full h-full object-contain scale-110 rounded-full"
                />
              </div>
            </div>
            <div className="flex-grow overflow-y-auto bg-white rounded-lg shadow-md p-4 mb-4">
              {messages.map((message, index) => (
                <ConversationMessage key={index} message={message} index={index} />
              ))}
              {isLoading && <LoadingMessage />}
              {activeTranscript && (
                <div className={`flex mb-6 ${activeTranscript.role === "user" ? "justify-end" : "justify-start"}`}>
                  <div className={`flex items-start space-x-2 max-w-[80%]`}>
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${activeTranscript.role === "user" ? "bg-[#ff6d00]" : "bg-[#0096D6]"} text-white`}>
                      {activeTranscript.role === "user" ? <User className="w-5 h-5" /> : <Bot className="w-5 h-5" />}
                    </div>
                    <div className={`${activeTranscript.role === "user" ? "bg-[#ff6d00]/10" : "bg-white border border-gray-200"} rounded-2xl p-4 shadow-sm`}>
                      <p className="text-sm text-gray-800 whitespace-pre-wrap">{activeTranscript.content}</p>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </main>

          {/* Footer */}
          <footer className="p-2 sm:p-4">
            <div className="container mx-auto flex items-center space-x-2 px-4 md:px-8 lg:px-16">
              <div className="flex-grow mr-2">
                <Input
                  type="text"
                  placeholder="Type your message..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyUp={handleInputKeyPress}
                  className="w-full px-3 py-2 text-base bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-orange-300 focus-visible:ring-orange-300"
                  ref={inputRef}
                />
              </div>
              <div className="flex-shrink-0 flex space-x-2">
                <Button
                  onClick={sendMessage}
                  className="bg-[#ff6d00] hover:bg-[#ff6d00]/80 text-white rounded-full p-2"
                  disabled={!userInput.trim()}
                >
                  <SendHorizontal className="h-5 w-5" />
                  <span className="sr-only">Send message</span>
                </Button>
                <Button
                  onClick={callStatus === CALL_STATUS.ACTIVE ? stop : start}
                  disabled={callStatus === CALL_STATUS.LOADING}
                  className={`rounded-full p-2 ${callStatus === CALL_STATUS.LOADING
                      ? "bg-gray-400 cursor-not-allowed"
                      : callStatus === CALL_STATUS.ACTIVE
                        ? "bg-[#ff3900] hover:bg-[#ff3900]/80"
                        : "bg-[#ff6d00] hover:bg-[#ff6d00]/80"
                    } text-white`}
                >
                  {callStatus === CALL_STATUS.LOADING ? (
                    <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
                  ) : callStatus === CALL_STATUS.ACTIVE ? (
                    <StopCircle className="h-5 w-5" />
                  ) : (
                    <Mic className="h-5 w-5" />
                  )}
                  <span className="sr-only">
                    {callStatus === CALL_STATUS.LOADING
                      ? "Loading..."
                      : callStatus === CALL_STATUS.ACTIVE
                        ? "Stop recording"
                        : "Start recording"}
                  </span>
                </Button>
              </div>
            </div>
          </footer>
        </div>
        <audio src={audioSrc} className="w-full hidden" ref={audioRef} controls />
      </div>
    );
  } else {
    return (
      <WelcomePage action={handleStart} />
    )
  }

}

export default AppWrapper;