export enum MessageTypeEnum {
   TRANSCRIPT = "transcript",
   FUNCTION_CALL = "tool-calls",
   FUNCTION_CALL_RESULT = "function-call-result",
   ADD_MESSAGE = "add-message",
   STATUS_UPDATE = "status-update",
   CONVERSATION_UPDATE = 'conversation-update',
 }
 
 export enum MessageRoleEnum {
   USER = "user",
   SYSTEM = "system",
   ASSISTANT = "assistant",
 }
 
 export enum TranscriptMessageTypeEnum {
   PARTIAL = "partial",
   FINAL = "final",
 }
 
 type MessageRole = 'assistant' | 'user' | 'tool';
 
 interface BasicMessage {
   role: MessageRole;
   content: string;
 }
 
 interface ToolCallFunction {
   name: string;
   arguments: string;
 }
 
 interface ToolCall {
   type: 'function';
   id: string;
   function: ToolCallFunction;
 }
 
 
 interface ToolCallResult extends BasicMessage {
   tool_call_id: string;
 }
 
 interface AssistantOrUserMessage extends BasicMessage { }
 
 
 
 interface ToolCallMessage extends BasicMessage {
   tool_calls: ToolCall[];
 }
 
 type ConversationMessage = AssistantOrUserMessage | ToolCallMessage | ToolCallResult;
 export interface TranscriptMessage extends BaseMessage {
   type: MessageTypeEnum.TRANSCRIPT;
   role: MessageRoleEnum;
   transcriptType: TranscriptMessageTypeEnum;
   transcript: string;
 }
 
 export interface FunctionCallMessage extends BaseMessage {
   type: MessageTypeEnum.FUNCTION_CALL;
   purpose: string
   data?: any
   toolCalls: ToolCall[]
 }
 export interface ConversationUpdateMessage extends BaseMessage {
   type: MessageTypeEnum.CONVERSATION_UPDATE;
   conversation: ConversationMessage[];
   messagesOpenAIFormatted: ConversationMessage[];
 
 }
 export interface StatusUpdateMessage extends BaseMessage {
   type: MessageTypeEnum.STATUS_UPDATE;
 
 }
 
 export interface ShowHomeMessage extends BaseMessage {
   type: MessageTypeEnum.FUNCTION_CALL_RESULT;
   purpose: "show-home-card"
   address: string;
   beds: number;
   baths: string;
   squareFeet: number;
   price: string;
   status: string;
   location: string;
   communityName: string;
   floorPlan: string;
   completionDate: string | null
   features: string | null
   imageUrls: string[];
 }
 
 
 export interface ShowFloorPlanMessage extends BaseMessage {
   name: string;
   beds: number;
   baths: string;
   style?: string;
   price: string;
   garageCapacity: string;
   layout: string;
   elevations: string[]
   squareFeet: number;
   imageUrls: string[];
   purpose: 'show-floorplan-card'
   type: MessageTypeEnum.FUNCTION_CALL_RESULT;
 }
 
 export interface ShowImagesMessage extends BaseMessage {
   type: MessageTypeEnum.FUNCTION_CALL_RESULT,
   image: string[],
   title: string,
   purpose: 'show-image-carousel'
 }
 
 export interface BaseMessage {
   type: MessageTypeEnum;
   timestamp: Date
 }
 
 export type Message =
   | TranscriptMessage
   | FunctionCallMessage
   | ShowHomeMessage
   | ShowImagesMessage
   | StatusUpdateMessage
   | ConversationUpdateMessage
   | ShowFloorPlanMessage
 
 