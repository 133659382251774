import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createUser } from './api';

const useUserUUID = () => {
   // Function to load the UUID from localStorage
   const loadUUID = () => {
      const storedUUID = localStorage.getItem('userUUID');
      if (storedUUID) {
         return storedUUID;
      }
      // If no UUID is found in localStorage, generate a new one
      const newUUID = uuidv4();
      createUser(newUUID)
      localStorage.setItem('userUUID', newUUID);
      return newUUID;
   };

   // State to hold the UUID
   const [uuid, setUuid] = useState('');

   useEffect(() => {
      setUuid(loadUUID());
   }, []);

   return uuid;
};

export default useUserUUID;