import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
export interface ModeStore {
   mode: 'chat' | 'voice';
   setMode: (newMode: 'chat' | 'voice') => void;
   callId: string | null;
   setCallId: (newCallId: string | null) => void;
   socketId: string | null;
   setSocketId: (newSocketId: string | null) => void;
   sessionId: string;
   generateNewSessionId: () => string;
   setSessionId: (newSessionId: string) => void;
}


export const useStore = create<ModeStore>((set) => ({
   mode: 'chat',
   setMode: (newMode) => set({ mode: newMode }),
   callId: null,
   setCallId: (newCallId) => set({ callId: newCallId }),
   socketId: null,
   setSocketId: (newSocketId) => set({ socketId: newSocketId }),
   sessionId: uuidv4(),
   generateNewSessionId: () => {
      const newId = uuidv4();
      set({ sessionId: newId });
      return newId;
   },
   setSessionId: (newSessionId) => set({ sessionId: newSessionId })
}));
