import { ResponseMessage } from "@/App";
import axios, { AxiosError } from "axios";


const handleApiError = (error: unknown, context: string): void => {
   if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
         console.error(`${context}: ${axiosError.response.status} - ${axiosError.response.data}`);
      } else if (axiosError.request) {
         console.error(`${context}: No response received`);
      } else {
         console.error(`${context}: ${axiosError.message}`);
      }
   } else {
      console.error(`${context}: An unexpected error occurred`, error);
   }
};


export async function fetchMessagesForSession(sessionId: string): Promise<ResponseMessage[]> {
   try {
      const response = await fetch(`/sessions/${sessionId}/messages`);

      if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);


      if (!Array.isArray(data.messages)) {
         throw new Error('Invalid response format: messages is not an array');
      }

      // Assuming the API returns an array of messages in the correct format
      return data.messages;
   } catch (error) {
      console.error('Error fetching messages:', error);
      throw error; // Re-throw the error so the caller can handle it
   }
}

export const saveCurrentSession = async (sessionID: string, messages: ResponseMessage[]): Promise<void> => {
   await fetch(`/sessions/${sessionID}`, {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({
         messages: messages,
      }),
   });
};

export const createUser = async (userID: string): Promise<void> => {
   try {
      const response = await axios.post(`/user`, {
         userID: userID,
      });
      console.log("User created successfully:", response.data);
   } catch (error) {
      handleApiError(error, "Error creating user");
   }
};


export const sendCallIdToServer = async (userID: string, callID: string, socketID: string | null): Promise<void> => {
   try {
      const response = await axios.post(`/call`, {
         userID: userID,
         callID: callID,
         sessionID: socketID
      });
      console.log("Call ID sent successfully:", response.data);
   } catch (error) {
      handleApiError(error, "Error sending call ID to server");
   }
};