import { Sheet, SheetContent, SheetTrigger } from './ui/sheet';
import { ScrollArea } from './ui/scroll-area';
import hpLogo from '../assets/hp-logo.svg'
import { useEffect, useState } from 'react';
import { Menu, MessageSquare, Plus } from 'lucide-react';
import { Button } from './ui/button';
import { socket } from '../utils/socket';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@/store/zustand';

interface Session {
   id: string;
   startTime: string;
   name: string | null
   // Add other relevant fields
}

interface SidebarProps {
   userUUID: string;
   onNewChat: () => void;
   onSelectSession: (sessionId: string) => void;
}

function Sidebar({ userUUID, onNewChat, onSelectSession }: SidebarProps) {
   const navigate = useNavigate();
   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
   const [sessions, setSessions] = useState<Session[]>([]);
   const [sessionToDeleteRef, setSessionToDeleteRef] = useState<string | null>(null);
   const disabled = useStore((state) => state.mode === 'voice');
   useEffect(() => {
      fetchSessions();
   }, [userUUID]);

   useEffect(() => {
      // Listen for session creation event
      socket.on('new-session', () => {
         fetchSessions();
      });

      return () => {
         socket.off('new-session');
      };
   }, []);

   const fetchSessions = async () => {
      try {
         const response = await fetch(`/sessions/${userUUID}`);
         if (!response.ok) {
            throw new Error('Failed to fetch sessions');
         }
         const data = await response.json();
         console.log(data,"sessions")
         setSessions(data);
      } catch (error) {
         console.error('Error fetching sessions:', error);
      }
   };

   const handleSessionSelect = (sessionId: string) => {
      onSelectSession(sessionId);
      navigate(`/chat/${sessionId}`);
   };

   const groupSessions = () => {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const lastWeek = new Date(today);
      lastWeek.setDate(lastWeek.getDate() - 7);

      return {
         today: sessions.filter(s => new Date(s.startTime) >= today),
         yesterday: sessions.filter(s => new Date(s.startTime) >= yesterday && new Date(s.startTime) < today),
         lastWeek: sessions.filter(s => new Date(s.startTime) >= lastWeek && new Date(s.startTime) < yesterday),
         older: sessions.filter(s => new Date(s.startTime) < lastWeek),
      };
   };

   const groupedSessions = groupSessions();

   const renderSessionGroup = (title: string, sessions: Session[]) => (
      sessions.length > 0 && (
         <div key={title}>
            <h3 className="px-3 py-2 text-sm font-semibold text-gray-500">{title}</h3>
            {sessions.map((session) => (
               <div
                  key={session.id}
                  className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${
                     session.id === sessionToDeleteRef ? 'bg-gray-100' : ''
                  } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={() => !disabled && handleSessionSelect(session.id)}
               >
                  <MessageSquare className="inline-block mr-2 h-4 w-4 text-gray-600" />
                  <span className="text-sm text-gray-800">
                     {session.name || new Date(session.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </span>
               </div>
            ))}
         </div>
      )
   );

   const sidebarContent = (
      <div className="flex flex-col h-full">
         <div className="p-4 flex items-center space-x-2 border-b border-gray-200">
            <img src={hpLogo} alt="EngaGER Logo" className="w-10 h-10 rounded" />
            <h1 className="text-xl font-bold text-gray-800 font-['Inter']">Engager</h1>
         </div>
         <div className="p-4">
            <Button 
               className="w-full bg-[#ff6d00] hover:bg-[#ff6d00]/80 text-white" 
               onClick={onNewChat}
               disabled={disabled}
            >
               <Plus className="mr-2 h-4 w-4" /> New Chat
            </Button>
         </div>
         <ScrollArea className="flex-grow">
            {renderSessionGroup("Today", groupedSessions.today)}
            {renderSessionGroup("Yesterday", groupedSessions.yesterday)}
            {renderSessionGroup("Last 7 Days", groupedSessions.lastWeek)}
            {renderSessionGroup("Older", groupedSessions.older)}
         </ScrollArea>
      </div>
   );

   return (
      <>
         <header className="md:hidden bg-white p-4 flex items-center justify-between border-b border-gray-200">
            <img src={hpLogo} alt="EngaGER Logo" className="w-8 h-8" />
            <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
               <SheetTrigger asChild>
                  <button className="p-2">
                     <Menu className="h-6 w-6" />
                  </button>
               </SheetTrigger>
               <SheetContent side="left" className="w-[250px] sm:w-[300px]">
                  {sidebarContent}
               </SheetContent>
            </Sheet>
         </header>

         <div className="hidden md:flex md:w-64 bg-white border-r border-gray-200 overflow-hidden flex-col">
            {sidebarContent}
         </div>
      </>
   )
}

export default Sidebar